import { DatatableHeader } from '../../components/datatable';
import { ColumnComponents } from '../../components/datatable/header/datatableHeader.types';

export const individualListHeaders: DatatableHeader[] = [
  {
    isOrderResultEnabled: false,
    key: 'name',
    label: 'Nome',
    width: '50%',
  },
  {
    isOrderResultEnabled: false,
    key: 'manage',
    label: 'Transferir',
    width: '6%',
    renderComponent: ColumnComponents.Edit,
  },
];
