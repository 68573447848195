import React from 'react';

import { ScrollView, Text, View } from 'react-native';
import { MFullWidthButton } from 'menupass-ui';
import { Header } from '@/components/header';
import { Container } from '@/components/container';
import locale from '@/internationalization';
import { Paper } from '@/components/paper';
import { colors } from '@/consts';
import { PaperColumns } from '@/styles/common/PaperColumns';
import { EntitiesPageTitle } from '@/components/entitiespagetitle';
import { PageStyles } from '@/styles/common/PageStyles';
import { Styles } from './IndividualTransferCreatePage.styles'
import { SearchableInput } from '@/components/searchableinput/SearchableInput';
import { ErrorHandler } from '@/components/errorhandler';
import IndividualTabs from '@/components/individualtabs/IndividualTabs';
import { useIndividualTransferCreate } from './IndividualTransferCreatePage.functios';
import { IndividualTransferCreatePageTypes } from './IndividualTransferCreatePage.types';

export const IndividualTransferCreatePage = ({  route: { params: { id } }} : IndividualTransferCreatePageTypes) => {
  const {
    adminData,
    entityList,
    entityName,
    adminClientName,
    hasAPIError,
    APIErrorMessage,
    disabled,
    send,
    cancel,
    setEntityName,
    setEntityId,
  } = useIndividualTransferCreate(id);
  return (
    <>
      <Header username={adminData?.name} role={adminData?.role} />
      <Container>
        <EntitiesPageTitle
          title={locale.t('individualTransfer.title')}
          exportPdf={() => console.log(null)}
          exportCsv={() => console.log(null)}
        />
        <Paper disableInternalPadding bgColor="transparent" hasSubmenu>
        <IndividualTabs individualList={false}  />
          <View style={[PaperColumns.tableContainerForm]}>
            <ScrollView>
              <View style={PaperColumns.container}>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.imageWrapper} />
                  <Text style={PageStyles.subtitle}>
                    {locale.t('rechargeTransferCreate.new')}
                  </Text>
                  <Text style={PageStyles.content}>
                    {locale.t('individualTransfer.content')}
                  </Text>
                </View>
                <View style={PaperColumns.column}>
                  <View style={PageStyles.subtitleHeader}>
                    <View />
                    <View style={PageStyles.headerButtons}>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('individualTransfer.cancel')}
                          bgColor={colors.newEntity.entitiesBtn}
                          labelColor={colors.newEntity.entitiesBtnTitle}
                          height={42}
                          onPress={cancel}
                          fontSize={14}
                        />
                      </View>
                      <View style={PageStyles.buttonsWrapper}>
                        <MFullWidthButton
                          label={locale.t('individualTransfer.save')}
                          height={42}
                          fontSize={14}
                          onPress={send}
                          disabled={
                           entityName === '' ||
                           adminClientName === '' ||
                           disabled
                          }
                        />
                      </View>
                    </View>
                  </View>
                  {hasAPIError && <ErrorHandler message={APIErrorMessage} />}
                  <View style={Styles.inputContainer}>
                    <View style={PageStyles.formRow}>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                       
                      <Text>{locale.t('individual')}: {adminClientName}</Text>
                      </View>
                      <View style={[PageStyles.inputWrapper, { width: '46%' }]}>
                        <SearchableInput
                          value={entityName}
                          onChange={inputValue => setEntityName(inputValue)}
                          label={locale.t('entity')}
                          data={entityList}
                          onSelect={item => {
                            setEntityId(item.value);
                            setEntityName(item.label);
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </Paper>
      </Container>
    </>
  );
};

export default IndividualTransferCreatePage;
