import { useEffect, useState } from 'react';
import { useGetAdmin, useGetAdminClientGuest, useGetAdminId } from '@/api/admin';
import { useGetEntitiesAll } from '@/api/entities';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import useNavigate from '@/navigation/routes/hooks/useNavigate';
import { Admin } from '@/types/admin';
import { useUpdateAdminClientGuest } from '@/api/signup';

export const useIndividualTransferCreate = (id: string) => {
  const navigation = useNavigate();

  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: adminClientGuestData, isLoading: loadingg } = useGetAdminClientGuest(true);
  const { data: entitiesResponseAll, isLoading: loading } = useGetEntitiesAll(adminId, !!adminId);

 
  const [adminClientName, setAdminClientName] = useState('');
  const [adminClientId, setAdminClientId] = useState('');
  const [entityName, setEntityName] = useState('');
  const [entityId, setEntityId] = useState('');
  const [hasAPIError, setHasAPIError] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState('');

  const [entityList, setEntityList] = useState<any[]>([]);
  const [disabled, setDisabled] = useState(false);

  const mutation = useUpdateAdminClientGuest();

  const send = async () => {
    setDisabled(true);
    setHasAPIError(false)
    setAPIErrorMessage('')
    try{
    
     const result = await mutation.mutateAsync({
        adminClientId,
        entityId,
      });
      
      if (result.status !== 201) {
        setDisabled(false);
        setHasAPIError(true);
        const err = locale.t('errors.400');
        setAPIErrorMessage(err);
        return;
      }
      setDisabled(false);
      navigation.navigate(Routes.IndividualList)
      window.location.reload();
    } catch (e) {
      console.error('useCategoryCreate.submit', e);
      setHasAPIError(true);
      setAPIErrorMessage(locale.t('errors.500'));
      setDisabled(false);
    }
  };

  const cancel = () => {
    navigation.navigate(Routes.IndividualList)
  };

  const formatList = () => {
    const list = entitiesResponseAll?.map((a: Admin) => ({
      label: a.name,
      value: a.id,
    }));
    const listSorted = list.sort((a: any, b: any) => a.label.localeCompare(b.label));

    setEntityList(listSorted);
  };

  useEffect(() => {
    if (entitiesResponseAll?.length && !loading) {
      formatList();
    }
  }, [entitiesResponseAll]);

  useEffect(() => {
    if (adminClientGuestData?.length && !loadingg) {
    const adminClient = adminClientGuestData.find((a: any) => a.id === id);

      setAdminClientId(adminClient?.id)
      setAdminClientName(adminClient?.name)

    }
  }, [adminClientGuestData]);


  return {
  adminData,
  entityList,
  entityName,
  adminClientName,
  hasAPIError,
  APIErrorMessage,
  disabled,
  send,
  cancel,
  setEntityName,
  setEntityId,
  };
};
