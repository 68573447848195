import { useEffect, useState } from 'react';
import { useGetAdmin, useGetAdminClientGuest, useGetAdminId } from '../../api/admin';
import { useNavigate } from '../../navigation/routes/hooks/useNavigate';
import { Admin } from '@/types/admin';

export const useIndividualList = () => {
  const { data: adminId } = useGetAdminId();
  const { data: adminData } = useGetAdmin(adminId, !!adminId);
  const { data: adminClientGuestData, isLoading: loading } = useGetAdminClientGuest(true);

  const { goToIndividualTransfer } = useNavigate();

  const [individualDatatable, setIndividualDatatable] = useState<any[]>([]);

  const formatList = (list: Admin[]) => {
    const listFormatted = list.map((i: Admin) => ({
      id: i.id,
      name: i.name,
      manage: '',
    }));

    setIndividualDatatable(listFormatted);
  };

const goToTransfer = (adminClientId: string) => {
    goToIndividualTransfer({id: adminClientId})
}

  useEffect(() => {
    if (adminClientGuestData?.length && !loading) {
      formatList(adminClientGuestData);
    }
  }, [adminClientGuestData]);

  return {
    adminData,
    loading,
    individualDatatable,
    goToTransfer,
  };
};
