import { useNavigation } from '@react-navigation/native';
import { IndividualTransferParams } from '../RoutesParams';
import { Routes } from '../Routes.types';

export const useNavigateIndividual = () => {
  const navigation = useNavigation();

  const goToIndividualTransfer = (params:  IndividualTransferParams) => {
    navigation.navigate(Routes.IndividualTransfer, params);
  };

  return { goToIndividualTransfer };
};

export default useNavigateIndividual;
