import fetch from '../fetch';
import { SignUpDto, UpdateAdminClientDto, VerificationCodeRequest } from './dto';

export const signUpRequest = (data: SignUpDto) => fetch('/signup/admin-entity', {
  method: 'POST',
  body: JSON.stringify(data),
});

export const verificationCodeRequest = (data: VerificationCodeRequest) => fetch('/verification-code', {
  method: 'POST',
  body: JSON.stringify(data),
});

export const updateAdminClient = (data: UpdateAdminClientDto) => fetch('/signup/admin-client/update', {
  method: 'PUT',
  body: JSON.stringify(data),
});
