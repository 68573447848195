import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Images } from '@/consts';
import locale from '@/internationalization';
import { Routes } from '@/navigation/routes';
import { headerStyles } from './Header.styles';
import { HeaderTypes } from './Header.types';
import { useHeader } from './Header.functions';

export const Header = ({ username, role }: HeaderTypes) => {
  const navigation = useNavigation();
  const {
    logout,
    menuIsOpened,
    setMenuIsOpened,
    menupassSuper,
  } = useHeader(role);

  return (
    <View style={headerStyles.container} testID="header">
      <View style={headerStyles.menuWrapper}>
        <TouchableOpacity
          style={headerStyles.logo}
          onPress={() => navigation.navigate(Routes.Home)}
        >
          <img
            src={Images.MiniLogo}
            alt={locale.t('imagesAlt.miniLogo')}
          />
        </TouchableOpacity>
        <View style={headerStyles.menu}>
          <TouchableOpacity
            style={headerStyles.menuItem}
            activeOpacity={0.7}
            onPress={() => navigation.navigate(Routes.Home)}
          >
            <Text style={headerStyles.menuItemText}>{locale.t('header.entities')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={headerStyles.menuItem}
            activeOpacity={0.7}
            onPress={() => navigation.navigate(Routes.Payment)}
          >
            <Text style={headerStyles.menuItemText}>{locale.t('header.payments')}</Text>
          </TouchableOpacity>
          {/* TODO add owner profile validation */}

          {menupassSuper && (
            <>
       
            <TouchableOpacity
              style={headerStyles.menuItem}
              activeOpacity={0.7}
              onPress={() => navigation.navigate(Routes.Plan)}>
              <Text style={headerStyles.menuItemText}>
                {locale.t('header.plans')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={headerStyles.menuItem}
              activeOpacity={0.7}
              onPress={() => navigation.navigate(Routes.Individual)}>
              <Text style={headerStyles.menuItemText}>
                {locale.t('individual')}
              </Text>
            </TouchableOpacity>
            </>
          )}
        </View>
      </View>
      <TouchableOpacity
        style={headerStyles.profile}
        onPress={() => setMenuIsOpened(!menuIsOpened)}
      >
        <View style={headerStyles.image}>
          <img
            src={Images.ProfileHeader}
            alt={locale.t('imagesAlt.profileHeader')}
          />
        </View>
        {menupassSuper ? (
          <Text style={headerStyles.username}>MenuPass</Text>
        ) : (
          <Text style={headerStyles.username}>{!!username && username}</Text>
        )}
        {menuIsOpened && (
          <View style={headerStyles.submenu}>
            { !menupassSuper && (
            <TouchableOpacity
              onPress={() => {
                setMenuIsOpened(false);
                navigation.navigate(Routes.Profile);
              }}
              style={headerStyles.submenuButton}
            >
              <Text style={headerStyles.submenuText}>{locale.t('header.editProfile')}</Text>
            </TouchableOpacity>
            )}
            <TouchableOpacity
              onPress={() => {
                setMenuIsOpened(false);
                logout();
              }}
              style={headerStyles.submenuButton}
            >
              <Text style={headerStyles.submenuText}>{locale.t('header.logout')}</Text>
            </TouchableOpacity>
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

export default Header;
