import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Routes } from '../routes';
import { IndividualParams } from '../routes/RoutesParams';
import IndividualListPage from '@/pages/individuallist/IndividualListPage';
import IndividualTransferCreatePage from '@/pages/individualtransfercreate/IndividualTransferCreatePage';

const Stack = createStackNavigator<IndividualParams>();

export const Individual = () => (
  <Stack.Navigator>
    <Stack.Screen
      name={Routes.IndividualList}
      component={IndividualListPage}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name={Routes.IndividualTransfer}
      component={IndividualTransferCreatePage}
      options={{ headerShown: false }}
    />
  </Stack.Navigator>
);

export default Individual;
